@import "styles/shared";

.Task-final-feedback {
  &__heading {
    @include heading(4);
    margin-bottom: $m_default;
    @media all and (min-width: $beta_query) {
      @include heading(3);
    }
  }
}
