@import "styles/shared";

.Main-footer {
  @include theme-background-color(c_body-bg);
  padding: $m_xxlarge 0;
  max-width: $w_main-maxwidth;
  margin: 0 auto;

  &--inChapter {
    max-width: $w_total-maxwidth;
    @include theme-background-color(c_section-bg);
    .Main-footer__inner {
      border-top: 2px solid transparent;
      @include theme-border-color(c_body-bg);
      @media all and (min-width: $charlie_query) {
        padding-right: 0;
        // padding-left: 0;
        margin-left: $w_menu-width;
      }
    }
  }
  &__inner {
    padding: $m_xxlarge 0 0;
    @include gutters;
    border-top: 2px solid transparent;
    @include theme-border-color(c_section-bg);
  }
  &__heading {
    @include heading(4);
    margin-bottom: $m_default;
  }
  &__listitem {
    margin-bottom: $m_default;
  }
  &__link {
    @include footerlink;
  }
}
