@import "styles/shared";

.Chapter-completed-overlay {
  @include gutters;
  text-align: left;
  &__heading {
    @include overlayHeading;
  }
  &__content {
    
  }
}
