@import "styles/shared";

.Course-summary-section {
  padding: $m_default $m_gutter_default;
  @media all and (min-width: $beta_query) {
    padding-top: $m_large;
    padding-bottom: $m_large;
  }
  margin-bottom: $m_xsmall;
  @include theme-background-color(c_section-bg);
  @include gutters;
  &__header {
    display: flex;
    justify-content: space-between;
  }
  &__heading {
    @include heading(4);
    @media all and (min-width: $beta_query) {
      @include heading(2);
    }
    @include theme-color(c_more-faded);
    border-bottom: 3px solid transparent;
    @include theme-border-color(c_more-faded);
    padding-bottom: $m_xsmall;
    margin-bottom: $m_small;
  }
  &__headinghighlight {
    @include theme-font-weight(fw_medium);
    @include theme-color(c_body-text);
  }
  &__headerlink {
    @include link;
    @include theme-font-weight(fw_medium);
    align-self: flex-start;
  }
  &__result {
    &:before {
      content: " ";
      display: inline-block;
      width: 9px;
      height: 9px;
      margin: 0 $m_small 0 0;
    }
    &--success {
      &:before {
        @include theme-background-color(c_success, true);
      }
    }
    &--fail {
      &:before {
        @include theme-background-color(c_fail, true);
      }
    }
  }
  &__total {
    margin-top: $m_small;
  }
}
