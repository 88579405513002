@mixin defaulttypo() {
  @include theme-font-size(s_default);
  line-height: $lh_default;
  @include theme-color(c_body-text);
  @include theme-font-family(ff_default);
  @include theme-font-weight(fw_normal);

  @media all and (min-width: $beta_query) {
    @include theme-font-size(s_medium);
    line-height: $lh_default;
  }
  @media all and (min-width: $delta_query) {
    @include theme-font-size(s_medium_large);
    line-height: $lh_default;
  }
  .is-resource-page &, &.is-resource-page {
    @media all and (min-width: $charlie_query) {
      @include theme-font-size(s_medium_large);
      line-height: $lh_default;
    }
  }
}
