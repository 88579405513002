@import "styles/shared";

.Rich-list-item {
  display: flex;
  &__image {
    flex: 0 0 auto;
    img {
      @include image;
      width: auto;
      height: 60px;
    }
  }
  &__body {
    flex-grow: 1;
    @include gutters;
    padding-right: 0 !important;
    align-self: center;
    @include generalcopymaxwidth;

    @include keeptextmedium;
    
  }
  &--largeImg {
    .Rich-list-item__image img {
      @media all and (min-width: $beta_query) {
        height: 96px;
      }
    }
  }
}
