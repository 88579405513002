@import "styles/shared";

.User-header {
  @include theme-background-color(c_section-inverse-bg);
  @include theme-font-size(s_medium_small);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: $m_gutter_default;
  @media all and (min-width: $beta_query) {
    padding-left: $m_gutter_large;
  }
  @media all and (min-width: $delta_query) {
    padding-left: $m_gutter_xlarge;
  }
  &__name {
    @include theme-color(c_button-inverse-bg-disabled);
    @include theme-font-weight(fw_medium);
    display: flex;
    flex-direction: column;
    span {
      @include heading;
      @include theme-color(c_body-bg);
    }
  }
  @media all and (min-width: $beta_query) {
    padding-top: $m_large;
    @include theme-font-size(s_medium);
  }
  &__navigation {
    display: flex;
    align-self: flex-end;
    &__menu-toggle,
    &__logout {
      width: $h_user-menu-mobile;
      height: $h_user-menu-mobile;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 9px;
      @include theme-font-weight(fw_black);
      @media all and (min-width: $beta_query) {
        width: $h_user-menu;
        height: $h_user-menu;
        @include theme-font-size(s_small);
      }
      svg {
        margin-top: $m_xsmall;
        height: 20px;
        width: 20px;
        @media all and (min-width: $beta_query) {
          margin-top: $m_small;
          height: 24px;
          width: 24px;
        }
      }
    }
    &__menu-toggle {
      background-color: $c_byggforetagen_fasttrack_menu;
      @include theme-color(c_action-primary);
    }
    &__logout {
      @include theme-background-color(c_action-primary);
      @include theme-color(c_body-bg);
    }
  }
}
