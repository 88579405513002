@import "styles/shared";

.Course-section-progress {
  &__progressbar {
    height: 3px;
    width: 100%;
    border-radius: $ra_default;
    @include theme-background-color(c_body-bg);

    @media all and (min-width: $charlie_query) {
      height: 6px;
      border-radius: $ra_large;
    }
    &-inner {
      background-color: transparent;
      height: 100%;
      border-radius: $ra_default;
      @media all and (min-width: $charlie_query) {
        border-radius: $ra_large;
      }
      transition: width 0.5s ease, background 0.5s ease;
    }
  }

  &__progresstext {
    margin-top: $m_default;
    @include theme-color(c_progress);
    @media all and (min-width: $beta_query) {
      @include theme-font-size(s_medium);
    }
    @include theme-font-weight(fw_medium);
    span {
      @include theme-font-weight(fw_medium);
    }
  }
  &--inBreadcrumb {
    .Course-section-progress__progressbar {
      @include theme-background-color(c_section-bg);
    }
    .Course-section-progress__progresstext {
      margin-top: $m_small;
    }
  }
  &--status1 {
    .Course-section-progress__progressbar-inner {
      background: transparent;
    }
  }
  &--status2,
  &--status3 {
    .Course-section-progress__progresstext {
      @include theme-color(c_body-text);
    }
    .Course-section-progress__highlight {
      @include theme-color(c_progress);
    }
    .Course-section-progress__progressbar-inner {
      @include theme-background-color(c_progress);
      @include theme-box-shadow(c_progress-faded, false, 0px, 0px, 12px);
    }
  }
  &--status4 {
    .Course-section-progress__progressbar-inner {
      @include theme-background-color(c_fail-bg);
    }
    .Course-section-progress__progresstext {
      @include theme-color(c_section-inverse-bg);
    }
  }
  &--status5 {
    .Course-section-progress__progressbar-inner {
      @include theme-background-color(c_success);
      @include theme-box-shadow(c_success-faded, false, 0px, 0px, 12px);
    }
    .Course-section-progress__progresstext {
      @include theme-color(c_success);
    }
  }
}
