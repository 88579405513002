@import "styles/shared";

.Task-result {
  text-align: left;
  padding: $m_large;
  @include gutters;
  
  &__title {
    @include theme-font-size(s_medium_small);
    line-height: $lh_medium;
    @include theme-font-weight(fw_bold);
    margin-bottom: $m_default;
    max-width: $w_general-copy-maxwidth;
    &:after {
      content: " ";
      display: block;
      width: 24px;
      height: 2px;
      margin-top: $m_default;
      @include theme-background-color(c_inverse-heading-border, true);
    }
  }
  &__subheading {
    @include heading(6);
  }
  ul.Task-result__list {
    @include bulletlist;
    @include theme-font-size(s_default);
  }
  ol.Task-result__list {
    @include numberedlist;
    @include theme-font-size(s_default);
  }
  
  &__failanswers {
    margin-bottom: $m_large;
  }
  &__stepresult {
    margin-top: $m_large;
  }
  &--isSuccess {
    border-left: 3px solid transparent;
    @include theme-border-color(c_success);
  }
  &--isFail {
    border-left: 3px solid transparent;
    @include theme-border-color(c_fail);
  }
  &--isEven {
    @include theme-background-color(c_inverse-bg);
  }
  &--isOdd {
    @include theme-background-color(c_section-inverse-bg);
  }
}
