@import "styles/shared";

@import "styles/shared";

.Task-slider-result {
  text-align: left;
  padding: $m_large;
  @include gutters;

  &__title {
    @include theme-font-size(s_medium_small);
    line-height: $lh_medium;
    @include theme-font-weight(fw_bold);
    margin-bottom: $m_default;
    max-width: $w_general-copy-maxwidth;
    &:after {
      content: " ";
      display: block;
      width: 24px;
      height: 2px;
      margin-top: $m_default;
      @include theme-background-color(c_border-inverse, true);
    }
  }
  &__subheading {
    @include heading(6);
    margin-bottom: $m_default;
  }

  &__gauge {
    position: relative;
    height: 16px;
    max-width: 250px;

    &:after {
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 2px;
      @include theme-background-color(c_border-inverse, true);
    }
  }
  &__indicator {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: $ra_round;
    border: 3px solid transparent;
    @include theme-border-color(c_border-inverse, true);
    height: 16px;
    width: 16px;
    border-radius: 50%;
    @include theme-background-color(c_button-default-bg, true);
    z-index: 1;
  }

  &__failanswers {
    margin-bottom: $m_large;
  }
  &--isSuccess {
    border-left: 3px solid transparent;
    @include theme-border-color(c_success);
  }
  &--isFail {
    border-left: 3px solid transparent;
    @include theme-border-color(c_fail);
  }
  &--isEven {
    @include theme-background-color(c_inverse-bg);
  }
  &--isOdd {
    @include theme-background-color(c_section-inverse-bg);
  }
}
