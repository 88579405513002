@font-face {
  font-family: "CircularWeb";
  src: url("../fonts/CircularXXWeb-Book.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "CircularWeb";
  src: url("../fonts/CircularXXWeb-BookItalic.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "CircularWeb";
  src: url("../fonts/CircularXXWeb-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "CircularWeb";
  src: url("../fonts/CircularXXWeb-Black.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "CircularWeb";
  src: url("../fonts/CircularXXWeb-ExtraBlack.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
