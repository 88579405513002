@import "styles/shared";

.Chapter-facts {
  // TODO find a good way to handle margins between
  // non-section blocks and regular blocks
  @include gutters;
  @media all and (min-width: $beta_query) {
    padding-bottom: 0;
  }

  &__section {
    margin: 0 -#{$m_gutter_default};
    @media all and (min-width: $beta_query) {
      margin: 0 -#{$m_gutter_large};
    }
    @media all and (min-width: $delta_query) {
      margin: 0 -#{$m_gutter_xlarge};
    }
  }
  @include clearfix;
}
