@mixin input($blocked: false) {
  @include theme-background-color(c_inverse-bg);
  padding: $m_default;
  @if $blocked {
    display: block;
    width: 100%;
  } @else {
    display: inline-block;
    width: auto;
  }
}
