@mixin gutters() {
  padding-left: $m_gutter_default;
  padding-right: $m_gutter_default;
  @media all and (min-width: $beta_query) {
    padding-left: $m_gutter_large;
    padding-right: $m_gutter_large;
  }
  @media all and (min-width: $delta_query) {
    padding-left: $m_gutter_xlarge;
    padding-right: $m_gutter_xlarge;
  }
  .is-resource-page &,
  &.is-resource-page {
    @media all and (min-width: $charlie_query) {
      padding-left: $m_gutter_xlarge;
      padding-right: $m_gutter_xlarge;
    }
  }
}
