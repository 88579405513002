@import "styles/shared";

.Section-verifier-overlay {
  text-align: center;
  &__heading {
    @include overlayHeading;
    
  }
  &__text {
    text-align: left;
    @include theme-font-size(s_medium);
    line-height: $lh_small;
    @include theme-font-weight(fw_medium);
    margin: $m_default 0;
    @media all and (min-width: $beta_query) {
      @include theme-font-size(s_medium_large);
      margin-bottom: $m_large;
    }
    &--error {
      @include theme-color(c_fail);
    }
  }
  &__btn {
    @include button($blocked: true, $type: "inverse");
    margin-top: $m_default;
    @media all and (min-width: $delta_query) {
      @include button($blocked: true, $type: "inverse", $extraHeight: true);
    }
  }
  &__footerheading {
    @include heading(4);
    margin-bottom: $m_small;
    @media all and (min-width: $delta_query) {
      @include heading(3);
    }
  }
  &__footer {
    padding: $m_default 0;
  }
  &__footertext {
    @include theme-font-weight(fw_medium);
    @include theme-font-size(s_medium_small);
  }
  &__retry {
    margin: 0 -#{$m_gutter_default};
    @media all and (min-width: $beta_query) {
      margin: 0 -#{$m_gutter_large};
    }
    @media all and (min-width: $charlie_query) {
      margin: 0;
    }
  }
  &--verified {
    .Section-verifier-overlay__footer {
      text-align: left;
      @include gutters;
      margin: 0 -#{$m_gutter_default};
      @media all and (min-width: $beta_query) {
        margin: 0 -#{$m_gutter_large};
      }
      @media all and (min-width: $charlie_query) {
        margin: 0;
      }
      @media all and (min-width: $delta_query) {
        padding-top: $m_large;
        padding-bottom: $m_large;
      }
      // padding-left: $m_gutter_default;
      // padding-right: $m_gutter_default;
    }
  }
  &.Section-verifier-overlay--verified.Section-verifier-overlay--success {
    .Section-verifier-overlay__footer {
      @include theme-background-color(c_success);
    }
  }
  &.Section-verifier-overlay--verified.Section-verifier-overlay--fail {
    .Section-verifier-overlay__footer {
      @include theme-background-color(c_fail-bg);
    }
  }
}
