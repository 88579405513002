@import "styles/shared";

.Section-result-view-overlay {
  text-align: left;
  // @include gutters;
  &__heading {
    @include overlayHeading;
  }
  &__text {
    margin: $m_default 0;
  }
  &__summary {
    display: flex;
    justify-content: space-between;
    max-width: 180px;
    // margin: 0 auto;
  }
  &__summarypart {
    position: relative;
    @include theme-font-size(s_default);
    @media all and (min-width: $beta_query) {
      @include theme-font-size(s_medium_small);
    }

    &:before {
      content: " ";
      display: inline-block;
      width: 9px;
      height: 9px;
      margin: 0 $m_small 0 0;
    }
    &--success {
      &:before {
        @include theme-background-color(c_success, true);
      }
    }
    &--fail {
      &:before {
        @include theme-background-color(c_fail, true);
      }
    }
  }
  &__chapters {
    margin-top: $m_large;
    margin-left: -#{$m_gutter_default};
    margin-right: -#{$m_gutter_default};
    @media all and (min-width: $beta_query) {
      margin-left: -#{$m_gutter_large};
      margin-right: -#{$m_gutter_large};
    }
    @media all and (min-width: $charlie_query) {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
