@import "styles/shared";

.Participant-course-summary-section {
  padding: $m_default $m_gutter_default;
  @media all and (min-width: $beta_query) {
    padding-top: $m_large;
    padding-bottom: $m_large;
  }
  margin-bottom: $m_xsmall;
  @include theme-background-color(c_section-bg);
  @include gutters;
  &__header {

  }
  &__heading {
    @include heading(4);
    @media all and (min-width: $beta_query) {
      @include heading(2);
    }
    // @include theme-color(c_progress);

    margin-bottom: $m_small;
  }
  &__subheading {
    font-weight: $fw_medium;
    // @include theme-color(c_progress);
  }
  &--isOngoing {
    .Participant-course-summary-section__heading {
      @include theme-color(c_progress);
    }
    .Participant-course-summary-section__subheading {
      @include theme-color(c_progress);
    }
  }
  &--isSuccess {
    
    .Participant-course-summary-section__heading {
      @include theme-color(c_success);
    }
    .Participant-course-summary-section__subheading {
      @include theme-color(c_success);
    }
    &:not(.Participant-course-summary-section--isVerified) {
      .Participant-course-summary-section__subheading--verifiedstatus {
        @include theme-color(c_body-text);
      }
    }
  }
  &__detailslink {
    @include link;
    @include theme-font-weight(fw_medium);
    @include theme-color(c_action-primary);
    margin-top: $m_default;
  }
  &__breakdownlist {
    margin: $m_default 0;
  }
  &__breakdownitem {
    &:before {
      content: " ";
      display: inline-block;
      width: 9px;
      height: 9px;
      margin: 0 $m_small 0 0;
      @include theme-background-color(c_fail, true);
    }
  }
  &__date {
    margin-top: $m_default;
  }
}
