@mixin richtext {
  ul {
    @include bulletlist;
  }
  ol {
    @include numberedlist;
  }
  p + p {
    margin-top: $m_default;
  }
}
