@import "styles/shared";

.Home {
  @include standardpage;
  a {
    @include link($underline: true);
  }
  p {
    margin: $m_default;
  }
  input {
    @include input;
    margin: 0 6px;
  }
  button {
    @include button;
  }
}