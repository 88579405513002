@import "styles/shared";

@mixin sliderThumbArrows() {
  position: relative;
  &:before {
    content: " ";
    display: block;
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;

    border-right: 6px solid transparent;
    @include theme-border-color(c_border-inverse);
  }
  &:after {
    // TODO right arrow
  }
}

.Slider-input {
  &__labels {
    display: flex;
    justify-content: space-between;
  }
  &__mintext {
    line-height: $lh_xsmall;
    @include theme-font-size(s_medium_small);
  }
  &__maxtext {
    line-height: $lh_xsmall;
    @include theme-font-size(s_medium_small);
  }

  &__datalist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: $m_default;
  }
  &__datalistitem {
  }
  &__element {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  &__element:focus {
    outline: none;
  }
  &__element::-webkit-slider-runnable-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    animate: 0.2s;
    // box-shadow: 0px 0px 1px #000000;
    background: none;
    @include theme-background-color(c_progress, true);
    border-radius: 12px;
    border: 0px solid #000000;
  }
  &__element::-webkit-slider-thumb {
    // box-shadow: 0px 0px 1px #000000;
    border: 3px solid transparent;
    @include theme-border-color(c_border-inverse, true);
    height: 33px;
    width: 33px;
    border-radius: 50%;
    @include theme-background-color(c_button-default-bg, true);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -9px;
    @media all and (min-width: $delta_query) {
      height: 36px;
      width: 36px;
      margin-top: -10.5px;
    }

    // TODO this doesnt work... Have to
    // find another way
    // @include sliderThumbArrows;
  }
  &__element:focus::-webkit-slider-runnable-track {
    background: none;
    @include theme-background-color(c_progress, true);
  }
  &__element::-moz-range-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    animate: 0.2s;
    // box-shadow: 0px 0px 1px #000000;
    background: none;
    @include theme-background-color(c_progress, true);
    border-radius: 12px;
    border: 0px solid #000000;
  }
  &__element::-moz-range-thumb {
    // box-shadow: 0px 0px 1px #000000;
    border: 3px solid transparent;
    @include theme-border-color(c_border-inverse, true);
    height: 24px;
    width: 24px;
    border-radius: 50px;
    @include theme-background-color(c_button-default-bg, true);
    cursor: pointer;
  }
  &__element::-ms-track {
    width: 100%;
    height: 16px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  &__element::-ms-fill-lower {
    background: none;
    @include theme-background-color(c_progress, true);
    border: 0px solid #000000;
    border-radius: 24px;
    // box-shadow: 0px 0px 1px #000000;
  }
  &__element::-ms-fill-upper {
    background: none;
    @include theme-background-color(c_progress, true);
    border: 0px solid #000000;
    border-radius: 24px;
    // box-shadow: 0px 0px 1px #000000;
  }
  &__element::-ms-thumb {
    // box-shadow: 0px 0px 1px #000000;
    border: 3px solid transparent;
    @include theme-border-color(c_border-inverse, true);
    height: 24px;
    width: 24px;
    border-radius: 50px;
    @include theme-background-color(c_button-default-bg, true);
    cursor: pointer;
  }
  &__element:focus::-ms-fill-lower {
    background: none;
    @include theme-background-color(c_progress, true);
  }
  &__element:focus::-ms-fill-upper {
    background: none;
    @include theme-background-color(c_progress, true);
  }
}
