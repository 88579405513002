@import "styles/shared";

.Chapter-intersection {
  color: inherit;

  &__inner {
  }

  &__text {
    margin-bottom: $m_large;
    @include theme-font-size(s_medium);
    @include theme-font-weight(fw_bold);
    span {
      @include theme-font-weight(fw_bold);
    }
  }
  &__btn {
    @include button($blocked: true, $type: "primary");
    flex: 1 0 100%;
    align-self: flex-start;
  }
  &__btn-icon {
    @include buttonIcon($type: "primary");
  }
  &__icon {
    margin-right: $m_gutter_default;
    margin-bottom: $m_large;
    align-self: flex-start;
    width: 51px;
    flex-basis: 51px;
    svg {
      width: 100%;
      height: auto;
      path {
        @include theme-fill(c_body-bg);
        &.highlight {
          @include theme-fill(c_action-primary);
        }
      }
    }
    @media all and (min-width: $beta_query) {
      margin-bottom: 0;
      margin-right: $m_gutter_large;
    }
    @media all and (min-width: $delta_query) {
      width: 72px;
      flex-basis: 72px;
    }
  }
  &--isInOverlay {
    .Chapter-intersection__icon {
      display: none;
    }
    .Chapter-intersection__text {
      @include theme-font-size(s_medium);
      line-height: $lh_small;
      @include theme-font-weight(fw_medium);
      margin-bottom: $m_default;
      @media all and (min-width: $beta_query) {
        @include theme-font-size(s_medium_large);
        margin-bottom: $m_large;
      }
      span {
        @include theme-font-weight(fw_medium);
        @media all and (min-width: $beta_query) {
          display: block;
        }
      }
      span + span {
        @media all and (min-width: $beta_query) {
          margin-top: $m_large;
        }
      }
    }
    .Chapter-intersection__btn {
      @include button($blocked: true, $type: "inverse");
      @media all and (min-width: $delta_query) {
        @include button($blocked: true, $type: "inverse", $extraHeight: true);
      }
    }
  }
  &.Chapter-intersection--isChapterCompleted:not(.Chapter-intersection--isInOverlay) {
    .Chapter-intersection__text {
      @include theme-color(c_progress);
    }
    .Chapter-intersection__icon {
      svg path.highlight {
        @include theme-fill(c_progress);
      }
    }
  }
  &.Chapter-intersection--isSectionSuccess:not(.Chapter-intersection--isInOverlay) {
    .Chapter-intersection__text {
      @include theme-color(c_success);
    }
    .Chapter-intersection__icon {
      svg path.highlight {
        @include theme-fill(c_success);
      }
    }
  }
  &:not(.Chapter-intersection--isInOverlay) {
    .Chapter-intersection__btn-icon {
      svg g.colorg {
        @include theme-stroke(c_button-primary-text);
      }
    }
    .Chapter-intersection__text {
      flex: 1;
      align-self: flex-start;
      @media all and (min-width: $beta_query) {
        margin-bottom: 0;
        max-width: 50%;
        padding-right: $m_gutter_default;
      }
      @media all and (min-width: $delta_query) {
        @include theme-font-size(s_medium_large);
      }
    }
    .Chapter-intersection__btn {
      @media all and (min-width: $beta_query) {
        flex: 0 0 auto;
        margin-left: auto;
        @include button($blocked: false, $type: "primary");
      }
      @media all and (min-width: $delta_query) {
        @include button($blocked: false, $type: "primary", $extraHeight: true);
      }
    }
    .Chapter-intersection__inner {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @media all and (min-width: $beta_query) {
        flex-wrap: initial;
      }
    }
  }
}
