@mixin infopage {
  @include standardpage;
  min-height: 0;
  padding: $m_xlarge $m_gutter_default;
  @include gutters;
  &__heading {
    @include heading(2);
    margin: 0 0 $m_large;
  }
  &__text {
    max-width: $w_general-copy-maxwidth;
    margin-bottom: $m_large;
  }
  &__button {
    @include button($blocked: true);
    margin-top: $m_large;
  }
  &__link {
    margin-top: $m_large;
    a {
      @include link;
    }
  }
}