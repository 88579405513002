@import "styles/shared";

[class^="Fact-block"] {
  // Regular links included in content.
  a {
    @include link($inverse: true);
  }
}

.Fact-block--CoreHeading {
  // TODO multiple sizes?
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include heading(4);
    @media all and (min-width: $beta_query) {
      @include heading(3);
    }
  }
  clear: both;
  margin: $m_large 0 $m_default;
  &--aligncenter {
    text-align: center;
  }
  &--alignright {
    text-align: right;
  }
  @media all and (min-width: $beta_query) {
    margin: $m_xlarge 0 $m_large;
  }
}

.Fact-block--CoreImage {
  margin: $m_default 0;
  @media all and (min-width: $beta_query) {
    margin: $m_large 0;
  }
  figure {
    display: block;
    img {
      @include image;
    }
  }
  &--isSvg {
    .size-thumbnail {
      img {
        width: 35%;
      }
    }
    .size-medium {
      img {
        width: 65%;
      }
    }
    .size-large:not(.is-resized),
    .size-full:not(.is-resized) {
      img {
        width: 100%;
      }
    }
  }
  figcaption {
    display: block;
    margin-top: $m_small;
    padding-bottom: $m_default;
    text-align: left;

    strong {
      @include theme-font-weight(fw_bold);
    }
  }

  &--align-center {
    padding: 0 $m_gutter_default $m_gutter_default;
    text-align: center;
    figure,
    figcaption {
      text-align: center;
    }
    @media all and (min-width: $beta_query) {
      padding: $m_xsmall $m_gutter_default $m_gutter_large;
    }
  }
  &--align-right {
    float: right;
    padding: 0 0 $m_gutter_default $m_gutter_default;
    figure {
      text-align: right;
    }
    @media all and (min-width: $beta_query) {
      padding: $m_xsmall 0 $m_gutter_large $m_gutter_large;
    }
  }
  &--align-left {
    float: left;
    padding: 0 $m_gutter_default $m_gutter_default 0;
    @media all and (min-width: $beta_query) {
      padding: $m_xsmall $m_gutter_large $m_gutter_large 0;
    }
  }
}

.Fact-block--CoreList {
  clear: both;
  ul {
    @include bulletlist;
  }
  ol {
    @include numberedlist;
  }
}

.Fact-block--CoreParagraph {
  margin: $m_default 0;
  @include generalcopymaxwidth;
  &--alignright {
    text-align: right;
  }
  &--alignleft {
    text-align: left;
  }
  &--aligncenter {
    text-align: center;
  }
}

.Fact-block--BucLmsBuilderSlider {
  clear: both;
  margin-top: $m_large;
  margin-bottom: $m_large;
  overflow: hidden;
  max-width: 100%;
}

.Fact-block--BucLmsBuilderRichListItem {
  clear: both;
  margin: $m_large 0;
}

.Fact-block--CoreEmbedVimeo {
  clear: both;
}

.Fact-block--AcfTask {
  clear: both;
  margin: $m_large 0;
}

.Fact-block--BucLmsBuilderColumns {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  margin: $m_default -#{$m_gutter_default / 2};
  @media all and (min-width: $beta_query) {
    @include theme-font-size(s_medium);
  }
  @media all and (min-width: $delta_query) {
    margin: $m_default -#{$m_gutter_large / 2};
  }
  .Fact-block--BucLmsBuilderColumnItem {
    min-width: 150px;
    padding: 0 $m_gutter_default / 2;
    @media all and (min-width: $delta_query) {
      padding: 0 $m_gutter_large / 2;
    }

    // Anything more than 4 cols will be
    // 25% for now
    width: 25%;
    flex: 1 0 25%;
  }
  &--1 {
    .Fact-block--BucLmsBuilderColumnItem {
      width: 100%;
      flex: 1 0 100%;
    }
  }
  &--2 {
    .Fact-block--BucLmsBuilderColumnItem {
      width: 50%;
      flex: 1 0 50%;
    }
  }
  &--3 {
    .Fact-block--BucLmsBuilderColumnItem {
      width: 33.33%;
      flex: 1 0 33.33%;
    }
  }
  &--4 {
    .Fact-block--BucLmsBuilderColumnItem {
      width: 25%;
      flex: 1 0 25%;
    }
  }
}

.Fact-block--BucLmsBuilderImageAreaMap,
.Fact-block--BucLmsBuilderImagePointMap {
  clear: both;

  @media all and (min-width: $beta_query) {
    &--sizesmall {
      .Fact-block--BucLmsBuilderImageAreaMap__canvaswrap,
      .Fact-block--BucLmsBuilderImagePointMap__canvaswrap {
        width: 35%;
      }
    }
    &--sizemedium {
      .Fact-block--BucLmsBuilderImageAreaMap__canvaswrap,
      .Fact-block--BucLmsBuilderImagePointMap__canvaswrap {
        width: 65%;
      }
    }
    &--alignright {
      .Fact-block--BucLmsBuilderImageAreaMap__canvaswrap,
      .Fact-block--BucLmsBuilderImagePointMap__canvaswrap {
        float: right;

        margin: 0 0 $m_gutter_default $m_gutter_default;
      }
    }
    &--alignleft {
      .Fact-block--BucLmsBuilderImageAreaMap__canvaswrap,
      .Fact-block--BucLmsBuilderImagePointMap__canvaswrap {
        float: left;

        margin: 0 $m_gutter_default $m_gutter_default 0;
      }
    }
    &--aligncenter {
      .Fact-block--BucLmsBuilderImageAreaMap__canvaswrap,
      .Fact-block--BucLmsBuilderImagePointMap__canvaswrap {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__canvaswrap {
    position: relative;
    margin: $m_large 0;
    canvas {
      width: 100%;
    }
  }
}

.Fact-block--BucLmsBuilderModal {
  clear: both;

  &--aligncenter {
    display: flex;
    justify-content: center;
  }
  &--alignleft {
    float: left;
  }
  &--alignright {
    float: right;
  }
  &__txtbtntrigger {
    margin: $m_large 0;
  }
  &__trigger-text {
    margin-bottom: $m_default;
    @include generalcopymaxwidth;
  }

  &__trigger-img {
    @include image;
  }

  &__trigger-btn {
    @include button($blocked: false, $type: "secondary");
    .Facts-section & {
      @include button($blocked: false, $type: "inverse");
    }
  }
}
.Fact-block--BucLmsBuilderModal__trigger-imgwrap {
  display: block;
  margin: $m_large 0;

  @media all and (min-width: $beta_query) {
    .Fact-block--BucLmsBuilderModal--sizesmall & {
      width: 35%;
    }
    .Fact-block--BucLmsBuilderModal--sizemedium & {
      width: 65%;
    }
    .Fact-block--BucLmsBuilderModal--sizelarge & {
      width: 100%;
    }
    .Fact-block--BucLmsBuilderModal--alignright & {
      float: right;
      margin-left: 0;
      margin-right: 0;
      padding: 0 0 $m_gutter_default $m_gutter_default;
    }
    .Fact-block--BucLmsBuilderModal--alignleft & {
      float: left;
      margin-left: 0;
      margin-right: 0;
      padding: 0 $m_gutter_default $m_gutter_default 0;
    }
    .Fact-block--BucLmsBuilderModal--aligncenter & {
      margin-left: auto;
      margin-right: auto;
      padding: 0 $m_gutter_default;
    }
    .Fact-block--BucLmsBuilderModal--isimg & {
      padding: 0;
    }
    .Fact-block--BucLmsBuilderModal--isSvg & {
      .Fact-block--BucLmsBuilderModal__trigger-img {
        width: 100%;
      }
    }
  }
}

// .Fact-block--BucLmsBuilderColumnItem {
//   .Fact-block--CoreImage {
//     float: none;
//     padding: 0;
//     figure {
//       text-align: center;
//     }
//     figcaption {
//       text-align: center;
//     }
//   }
// }

.Fact-block--CoreTable {
  margin: $m_default 0;
  @media all and (min-width: $beta_query) {
    margin: $m_large 0;
  }
  figure {
    @media all and (min-width: $delta_query) {
      @include theme-font-size(s_medium);
    }
    &.is-style-stripes {
      table tbody tr:nth-child(odd) {
        @include theme-background-color(c_oddrow);
        .Facts-section & {
          @include theme-background-color(c_oddrow-inverse);
        }
      }
    }
    &:not(.is-style-stripes) th:first-child,
    &:not(.is-style-stripes) td:first-child {
      padding-left: 0;
    }
    &:not(.is-style-stripes) th:last-child,
    &:not(.is-style-stripes) td:last-child {
      padding-right: 0;
    }
    table {
      border-collapse: collapse;
      width: 100%;
      &.has-fixed-layout {
        table-layout: fixed;
      }
      thead {
        border-bottom: 1px solid;
        @include theme-border-color(c_border-section);
      }
      th {
        @include theme-font-weight(fw_bold);
        text-align: center;
      }
      td {
        text-align: left;
        strong {
          @include theme-font-weight(fw_bold);
        }
      }
      th,
      td {
        padding: $m_small;
        &[data-align="left"] {
          text-align: left;
        }
        &[data-align="center"] {
          text-align: center;
        }
        &[data-align="right"] {
          text-align: right;
        }
      }
      tfoot {
        border-top: 1px solid;
        @include theme-border-color(c_border-section);
      }
    }
    figcaption {
      display: block;
      margin-top: $m_small;
      padding-bottom: $m_default;
      text-align: center;

      strong {
        @include theme-font-weight(fw_bold);
      }
    }
  }
}
.Fact-block--BucLmsBuilderRepetitionQuestion {
  margin: $m_default 0;

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    @media all and (min-width: $beta_query) {
      justify-content: space-between;
    }
  }
  &__label {
    @include heading(6);

    display: block;
    margin-bottom: $m_small;
  }

  &__input-area,
  &__result {
    width: 100%;

    @media all and (min-width: $beta_query) {
      width: calc(50% - #{$m_default});
    }
  }
  &__input-area {
    margin: $m_default 0;
  }
  &__input,
  &__result__text {
    @include theme-background-color(c_oddrow-inverse);
    @include theme-font-family(ff_default);
    @include theme-font-size(s_medium_small);
    border: 2px solid transparent;
    width: 100%;
  }
  &__input {
    @include theme-border-color(c_border-section);
    padding: $m_default;
    min-height: $h_repetition-textarea;
    height: calc(100% - #{$m_large});
    display: block;
    resize: vertical;
    outline: 0;

    &--active {
      @include theme-border-color(c_progress);
    }
  }

  &__result {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-$m_large);
    transition: transform $tr_default-speed ease,
      opacity $tr_default-speed linear;
    -webkit-transition: transform $tr_default-speed ease,
      opacity $tr_default-speed linear;
    &__text {
      cursor: default;
      padding: 0 $m_default;
      min-height: $h_repetition-textarea;
      height: auto;
      @include theme-border-color(c_success);
      @media all and (min-width: $beta_query) {
        height: calc(100% - #{$m_large});
      }
    }
    &--visible {
      margin: $m_default 0;
      visibility: visible;
      opacity: 1;
      transform: translateY(0);
    }
  }
  &__show-result {
    margin-top: $m_default;
    &__btn {
      @include button($type: "inverse");
      margin-bottom: $m_default;
      @media all and (min-width: $delta_query) {
        @include button($blocked: false, $type: "inverse", $extraHeight: true);
      }
    }
    &__text {
      @media all and (min-width: $beta_query) {
        padding: $m_default 0;
      }
    }
  }
}
