@import "styles/shared";

.Participant-search {
  @include standardpage;
  &__header {
    padding-top: $m_large;
    padding-bottom: $m_large;
    @include gutters;
  }
  &__mainheading {
    @include heading(2);
  }
  &__tabs {
    display: flex;
  }
  &__tab {
    padding: $m_large $m_gutter_default;
    flex: 1;
    text-align: center;
    cursor: pointer;


    @include theme-color(c_action-primary);
    @include theme-font-weight(fw_medium);
    &--active {
      @include theme-background-color(c_section-bg);
      @include theme-color(c_body-text);
    }
  }
  &__form {
    @include theme-background-color(c_section-bg);
    padding-top: $m_large;
    padding-bottom: $m_large;
    @include gutters;
  }
  &__formgroup {
    margin-bottom: $m_large;
  }
  &__label {
    display: block;
    margin-bottom: $m_small;
  }
  &__input {
    @include input($blocked: true);
  }
  &__submitbtn {
    @include button($type: primary, $blocked: true);
  }
  &__noresults {
    padding: $m_large;
    @include gutters;
  }
  &__error {
    padding: $m_large;
    @include gutters;
    @include theme-color(c_fail);
  }
}
