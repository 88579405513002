@import "styles/shared";

.Course-section-chapter-nav {
  z-index: 1;
  @include theme-background-color(c_section-bg);
  position: fixed;

  top: 0;
  bottom: 0;
  height: 100vh;
  left: -999em;
  overflow-x: hidden;
  overflow-y: scroll;
  pointer-events: auto;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 128px; // To give some scrolling space
  opacity: 0;
  transform: scale($sc_initial-hidden);
  transition: opacity $tr_default-speed ease, transform $tr_default-speed ease,
    left 0s ease $tr_default-speed;
  width: 100%;
  max-width: none;
  @media all and (min-width: $charlie_query) {
    padding-bottom: 0;
    max-width: $w_menu-width;
    // top: $h_breadcrumb-beta;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    height: auto;
    position: relative;
    flex: 1 0 $w_menu-width;
    overflow-y: hidden;
  }

  &--expanded {
    opacity: 1;
    transform: scale(1);
    transition: opacity $tr_default-speed ease, transform $tr_default-speed ease,
      left 0s ease 0s;

    left: 0;
  }
}
