$button-disabled-opacity: 0.5;

@mixin button($type: secondary, $blocked: false, $extraHeight: false) {
  appearance: none;
  padding: $m_default $m_gutter_default;
  @include gutters;
  @include theme-background-color(c_button-default-bg);
  @include theme-color(c_button-default-text);
  @include theme-border-radius(ra_buttons);
  line-height: $lh_medium;
  letter-spacing: normal;
  display: inline-block;
  position: relative;
  min-height: $h_button;
  font: normal 0px sans-serif; // Need this to overwrite chrome defaults
  @include theme-font-weight(fw_bold);
  @include theme-font-size(s_medium);
  @include theme-font-family(ff_default);

  @media all and (min-width: $beta_query) {
    padding-left: $m_xxlarge;
    padding-right: $m_xxlarge;
  }
  @media all and (min-width: $charlie_query) {
    font: normal 0px sans-serif;
    @include theme-font-size(s_medium_large);
    @include theme-font-weight(fw_bold);

    @include theme-font-family(ff_default);
  }
  @if $blocked {
    display: block;
    width: 100%;
  } @else {
    display: inline-block;
    width: auto;
  }

  @if $extraHeight {
    padding-top: $m_large;
    padding-bottom: $m_large;
  }

  &:disabled {
    @include theme-background-color(c_button-default-bg-disabled, true);
    @include theme-color(c_button-default-text-disabled, true);
  }

  @if $type == "inverse" {
    @include theme-background-color(c_button-inverse-bg);
    @include theme-color(c_button-inverse-text);
    &:disabled {
      @include theme-background-color(c_button-inverse-bg-disabled, true);
      @include theme-color(c_button-default-text-disabled, true);
    }
  } @else if $type == "primary" {
    @include theme-background-color(c_button-primary-bg);
    @include theme-color(c_button-primary-text);
    &:disabled {
      @include theme-background-color(c_button-primary-bg-disabled, true);
      @include theme-color(c_button-primary-text-disabled, true);
    }
  }
}

@mixin buttonIcon($type: secondary) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: $m_gutter_default;
  width: 14px;
  line-height: 1;
  @media all and (min-width: $delta_query) {
    // right: $m_gutter_large;
    width: 24px;
  }
  svg {
    width: 100%;
    height: auto;
    display: inline-block;
  }
  @if $type == "secondary" {
    svg path {
      @include theme-fill(c_button-default-text);
    }
  }
  @if $type == "inverse" {
    svg path {
      @include theme-stroke(c_button-inverse-text);
    }
  } @else if $type == "primary" {
    svg path {
      @include theme-fill(c_button-primary-text);
    }
  }
}
