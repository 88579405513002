@import "styles/shared";

.Chapters-retry-list {
  position: relative;
  &__loading {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding-top: 45px;
    @include theme-background-color(c_inverse-bg-faded);
  }
  &__nextchapter {
    @include gutters;
    margin-top: $m_default;
    @media all and (min-width: $beta_query) {
      margin-top: $m_large;
    }
  }
  &__nextchapterbtn {
    @include button($blocked: true, $type: "inverse");
    @media all and (min-width: $delta_query) {
      @include button($blocked: true, $type: "inverse", $extraHeight: true);
    }
  }
  &__nextchapterbtnicon {
    @include buttonIcon();
    svg g.colorg {
      @include theme-stroke(c_button-inverse-text);
    }
  }
}
