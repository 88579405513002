@import "styles/shared";

.Task-option-feedback {
  &__inner {
    margin-top: $m_medium_large;
    @include theme-font-size(s_medium);
    line-height: $lh_small;
    margin-bottom: $m_default;
    
    @media all and (min-width: $beta_query) {
      @include theme-font-size(s_medium_large);
    }
  }
  &__image {
    img {
      @include image;
    }
  }
  &__rich-text {
    @include richtext;
    @include gutters;
    padding-right: 0;
    border-left-width: 2px;
    border-left-style: solid;
    @include theme-border-left-color(c_inverse-text);
  }
  &__rich-text + &__image,
  &__image + &__rich-text {
    margin-top: $m_default;
  }
}
