@import "styles/shared";

.Login-bridge {
  @include infopage;

  &__text {
    &--warning {
      font-style: italic;
    }
  }
}
