@import "styles/shared";

.Course-section-select-item {
  padding: $m_default 0 $m_large;
  @include gutters;
  text-align: center;
  @include theme-background-color(c_section-bg);
  & + & {
    margin-top: $m_default;
    @media all and (min-width: $beta_query) {
      margin-top: $m_large;
    }
  }

  @media all and (min-width: $beta_query) {
    padding-bottom: $m_xlarge;
  }

  &__heading {
    @include heading(2);
    @media all and (min-width: $beta_query) {
      @include heading(1);
      line-height: $lh_xsmall;
    }
    margin: $m_xlarge 0 $m_default;
  }

  &__progress {
    @media all and (min-width: $beta_query) {
      @include generalcopymaxwidth;
      margin-left: auto;
      margin-right: auto;
    }
    @media all and (min-width: $charlie_query) {
      margin-bottom: $m_large;
    }
  }

  &--status4 {
    // Nothing special right now
  }

  &--status5 {
    @include theme-background-color(c_success-bg);
  }

  &__text {
    margin: $m_default auto $m_large;
    @include generalcopymaxwidth;
  }

  &__button {
    @include button($blocked: true);
    @media all and (min-width: $beta_query) {
      @include generalcopymaxwidth;
      margin: 0 auto;
    }
  }
  &__link {
    @include link($underline: false);
    @include theme-color(c_success);
    display: inline;
  }
}
