@import "styles/shared";

.Course-header {
  padding: $m_default;
  @include theme-background-color(c_body-bg);
  @include gutters;

  @media all and (min-width: $beta_query) {
    padding-top: $m_large;
  }

  &--withBreadcrumb {
    @media all and (min-width: $charlie_query) {
      @include theme-box-shadow(c_shadow, false, 0px, 3px, 6px);
      z-index: 2;
    }
  }

  position: relative;
  transition: background $tr_default-speed ease 0s; // wait to prevent flicker
  &__initial {
    display: flex;
    align-items: flex-start;
  }
  &__heading {
    @include heading(3);
    white-space: nowrap;
    position: relative;
    flex: 2 1 auto;
    overflow: hidden;
    @media all and (min-width: $charlie_query) {
      @include heading(1);
    }
    color: inherit;
    &:after {
      content: " ";
      display: block;
      width: 60px;
      position: absolute;
      bottom: 0;
      top: 0;
      right: 0;
      background: rgb(255, 255, 255);

      @include theme-diverge($theme-name_byggforetagen) {
        background: $gr_byggforetagen_headerfade;
      }
      @include theme-diverge($theme-name_arrangorsutbildningar) {
        background: $gr_byggforetagen_headerfade;
      }
      @include theme-diverge($theme-name_buc) {
        background: $gr_buc_headerfade;
      }
    }
  }
  &__logo {
    @include theme-diverge($theme-name_byggforetagen) {
      flex: 1 1 27px;
      max-width: 27px;
    }
    @include theme-diverge($theme-name_arrangorsutbildningar) {
      flex: 1 1 60px;
      max-width: 60px;
    }
    @include theme-diverge($theme-name_buc) {
      flex: 1 1 24px;
      max-width: 24px;
    }

    svg {
      vertical-align: middle;
    }
    @media all and (min-width: $charlie_query) {
      @include theme-diverge($theme-name_byggforetagen) {
        flex: 1 1 40px;
        max-width: 40px;
      }
      @include theme-diverge($theme-name_arrangorsutbildningar) {
        flex: 1 1 120px;
        max-width: 120px;
        svg {
          width: 120px;
          height: auto;
        }
      }
      @include theme-diverge($theme-name_buc) {
        flex: 1 1 40px;
        max-width: 40px;
      }
      svg {
        width: 40px;
        height: auto;
      }
    }
  }
  &__prefacelink {
    width: 100%;
    flex: 1 0 100%;
    margin-top: -5px;
    @media all and (min-width: $beta_query) {
      margin-top: 0px;
    }

    a {
      @include link($useChevron: true);
      @include theme-color(c_body-text);
    }
    &--expanded {
      a svg {
        transform: rotate(-90deg);
      }
    }
  }
  &__navtoggle {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px 0 0 0;
    @include gutters;
    @media all and (min-width: $beta_query) {
      padding-top: 26px;
      svg {
        width: 32px;
        height: 24px;
        path {
          @include theme-fill(c_action-primary);
        }
      }
    }
  }
  &__navtoggleicon {
    @include plussign($width: 9px);
    margin-left: $m_xsmall;
    transform: rotate(45deg);
  }
  &--isInNav {
    transition: background $tr_default-speed ease $tr_default-speed; // wait to prevent flicker
    &.Course-header--sectionstatus1,
    &.Course-header--sectionstatus2 {
      @include theme-background-color(c_progress);
      @include theme-color(c_inverse-text-emph);
    }
    &.Course-header--sectionstatus5 {
      @include theme-background-color(c_success);
      @include theme-color(c_inverse-text-emph);
    }

    .Course-header__navtoggle {
      position: static;
      padding: 0 0 $m_medium_large;
      svg {
        path {
          @include theme-fill(c_inverse-text-emph);
        }
      }
    }
  }
}
