@import "styles/shared";

.Overlay {
  @include theme-background-color(c_inverse-bg);
  @include theme-color(c_inverse-text);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;

  &__inner {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    pointer-events: auto;
  }
  &__innerinner {
    max-width: $w_main-maxwidth;
    margin: 0 auto;
    padding-bottom: 128px; // To give some scrolling space
    @include gutters;
    @media all and (min-width: $delta_query) {
      position: relative;
      padding-left: 0;
      padding-right: 0;
    }
  }
  &--isSimple {
    @include theme-background-color(c_translucent-backdrop);
    @include keeptextmedium;
    .Overlay__inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .Overlay__innerinner {
      @include theme-background-color(c_inverse-bg);
      @include theme-border-radius(ra_default);
      width: 90%;
      max-width: 600px;
      max-height: 90vh;
      overflow-y: auto;
      justify-self: center;
      align-self: center;
      @include gutters;
      @media all and (min-width: $delta_query) {
        padding-left: $m_large;
        padding-right: $m_large;
      }
      padding-bottom: $m_large;
      padding-top: $m_large;
    }
  }
}

.overlayanim-enter,
.overlayanim-appear {
  transform: scale($sc_initial-hidden);
  opacity: 0;
}

.overlayanim-enter-active,
.overlayanim-appear-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity $tr_default-speed, transform $tr_default-speed ease;
}

.overlayanim-exit {
  opacity: 1;
  transform: scale(1);
}

.overlayanim-exit-active {
  transform: scale($sc_initial-hidden);
  opacity: 0;
  transition: opacity $tr_default-speed, transform $tr_default-speed ease;
}
