@import "styles/shared";

.Task-interaction-match {
  display: flex;
  justify-content: space-between;
  &__wrapper {
    width: 50%;
  }
  &__static-card {
    display: flex;
    align-items: center;
    position: relative;
    @include theme-font-size(s_medium_small);
    line-height: $lh_xsmall;
    @include theme-font-weight(fw_medium);
    hyphens: auto;
    -webkit-hyphenate-character: auto;
    -moz-hyphens: auto;
    height: $h_task-match-option-card;
    border: 1px solid transparent;
    @include theme-border-color(c_backdrop);
    @include theme-background-color(c_backdrop);
    padding: $m_default;
    margin-left: 0;
    margin-right: $m_default;
    margin-bottom: $m_default;
    transition: margin-left $tr_default-speed ease,
      margin-right $tr_default-speed ease;
    &--puzzled {
      margin-left: $m_default;
      margin-right: 0;
      transition: margin-left $tr_default-speed ease,
        margin-right $tr_default-speed ease;
      border: 1px solid transparent;
      border-right: none;
      @include theme-border-color(c_inverse-bg);
      @include theme-background-color(c_progress);
    }
    &:not(&--puzzled)::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 14px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 16px;
      top: 50%;
      right: -2px;
      transform: translateY(-50%);
      @include theme-border-color(c_inverse-bg, $isPseudo: true);
      @include theme-background-color(c_inverse-bg, $isPseudo: true);
    }
    &__text {
      // truncate at 3 lines
      display: -webkit-inline-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      max-height: 48px;
      overflow: hidden;
      white-space: normal;
      padding: 1px 0;
    }
  }
}
