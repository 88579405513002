@import "styles/shared";

// https://projects.lukehaas.me/css-loaders/

$main-loading-duration: 6.8s;

// Main loading always uses default buc theme stuff to avoid
// switching mid-load!
.Main-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: map-deep-get($THEMES, $theme-name_buc, "c_body-bg");
  z-index: 1;

  &__inner {
    min-height: 35vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  &__text {
    margin-top: $m_large;
    color: map-deep-get($THEMES, $theme-name_buc, "c_action-primary");
    text-align: center;
    font-weight: map-deep-get($THEMES, $theme-name_buc, "fw_medium");
    font-family: map-deep-get($THEMES, $theme-name_buc, "ff_default");
    font-size: map-deep-get($THEMES, $theme-name_buc, "s_default");
    line-height: $lh_default;

    @media all and (min-width: $beta_query) {
      font-size: map-deep-get($THEMES, $theme-name_buc, "s_medium");
    }
    @media all and (min-width: $delta_query) {
      font-size: map-deep-get($THEMES, $theme-name_buc, "s_medium_large");
    }
  }

  .book {
    width: 32px;
    height: 12px;
    position: relative;
    margin: 32px 0 0 0;
    //Demo
    zoom: 1.5;
    transform: scale(0.7);
    .innerbook {
      width: 32px;
      height: 12px;
      position: relative;
      transform-origin: 2px 2px;
      transform: rotateZ(-90deg);
      animation: book $main-loading-duration ease infinite;
      .left,
      .right {
        width: 60px;
        height: 4px;
        top: 0;
        border-radius: 2px;
        background-color: map-deep-get(
          $THEMES,
          $theme-name_buc,
          "c_action-primary"
        );
        position: absolute;
        &:before {
          content: "";
          width: 48px;
          height: 4px;
          border-radius: 2px;
          background: inherit;
          position: absolute;
          top: -10px;
          left: 6px;
        }
      }
      .left {
        right: 28px;
        transform-origin: 58px 2px;
        transform: rotateZ(90deg);
        animation: left $main-loading-duration ease infinite;
      }
      .right {
        left: 28px;
        transform-origin: 2px 2px;
        transform: rotateZ(-90deg);
        animation: right $main-loading-duration ease infinite;
      }
      .middle {
        width: 32px;
        height: 12px;
        border: 4px solid transparent;
        border-color: map-deep-get(
          $THEMES,
          $theme-name_buc,
          "c_action-primary"
        );
        border-top: 0;
        border-radius: 0 0 9px 9px;
        transform: translateY(2px);
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      position: absolute;
      left: 50%;
      top: 0;
      li {
        height: 4px;
        border-radius: 2px;
        transform-origin: 100% 2px;
        width: 48px;
        right: 0;
        top: -10px;
        position: absolute;
        background-color: map-deep-get(
          $THEMES,
          $theme-name_buc,
          "c_action-primary"
        );
        transform: rotateZ(0deg) translateX(-18px);
        animation-duration: $main-loading-duration;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
        $i: 0;
        @while $i < 19 {
          &:nth-child(#{$i}) {
            animation-name: page-#{$i};
          }
          $i: $i + 1;
        }
      }
    }
  }
}

$i: 0;
@while $i < 19 {
  $delay: $i * 1.86;
  $delay-after: $i * 1.74;
  @keyframes page-#{$i} {
    #{4 + $delay}% {
      transform: rotateZ(0deg) translateX(-18px);
    }
    #{13 + $delay-after}%,
    #{54 + $delay}% {
      transform: rotateZ(180deg) translateX(-18px);
    }
    #{63 + $delay-after}% {
      transform: rotateZ(0deg) translateX(-18px);
    }
  }
  $i: $i + 1;
}

@keyframes left {
  4% {
    transform: rotateZ(90deg);
  }
  10%,
  40% {
    transform: rotateZ(0deg);
  }
  46%,
  54% {
    transform: rotateZ(90deg);
  }
  60%,
  90% {
    transform: rotateZ(0deg);
  }
  96% {
    transform: rotateZ(90deg);
  }
}

@keyframes right {
  4% {
    transform: rotateZ(-90deg);
  }
  10%,
  40% {
    transform: rotateZ(0deg);
  }
  46%,
  54% {
    transform: rotateZ(-90deg);
  }
  60%,
  90% {
    transform: rotateZ(0deg);
  }
  96% {
    transform: rotateZ(-90deg);
  }
}

@keyframes book {
  4% {
    transform: rotateZ(-90deg);
  }
  10%,
  40% {
    transform: rotateZ(0deg);
    transform-origin: 2px 2px;
  }
  40.01%,
  59.99% {
    transform-origin: 30px 2px;
  }
  46%,
  54% {
    transform: rotateZ(90deg);
  }
  60%,
  90% {
    transform: rotateZ(0deg);
    transform-origin: 2px 2px;
  }
  96% {
    transform: rotateZ(-90deg);
  }
}
