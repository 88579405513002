@import "styles/shared";

$trianglesize: 6px;
$trianglesize_beta: 8px;

$pausebar_width: 4px;
$pausebar_height: 12px;
$pausebar_width_beta: 4px;
$pausebar_height_beta: 14px;

.Sound-player {
  position: relative;
  @media all and (min-width: $beta_query) {
    @include theme-font-size(s_medium);
  }
  &__audioel {
    position: absolute;
    left: -999em;
  }
  &__playpause {
    @include link;
    @include theme-color(c_sound-action);
    text-transform: uppercase;
    @include theme-font-weight(fw_medium);
    display: inline-block;
    position: relative;
    padding-left: $m_small + $trianglesize * 2;
    @media all and (min-width: $beta_query) {
      padding-left: $m_small + $trianglesize_beta * 2;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 1px;
      left: 0;

      width: 0;
      height: 0;
      border-top: $trianglesize solid transparent;
      border-bottom: $trianglesize solid transparent;

      border-left-style: solid;
      border-left-width: $trianglesize * 1.8;
      @include theme-border-left-color(c_sound-action, true);

      @media all and (min-width: $beta_query) {
        border-top: $trianglesize_beta solid transparent;
        border-bottom: $trianglesize_beta solid transparent;

        border-left-style: solid;
        border-left-width: $trianglesize_beta * 1.8;
        @include theme-border-left-color(c_sound-action, true);
      }
    }
  }
  &--isPlaying {
    .Sound-player__playpause {
      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        border: 0 none;
        width: $pausebar_width;
        height: $pausebar_height;
        background: none transparent;
        @include theme-background-color(c_sound-action, true);
        left: 0;
        top: 1px;
        bottom: 0;
        @media all and (min-width: $beta_query) {
          width: $pausebar_width_beta;
          height: $pausebar_height_beta;
          top: 2px;
        }
      }
      &:after {
        left: $pausebar_width * 2;
        @media all and (min-width: $beta_query) {
          left: $pausebar_width_beta * 2;
        }
      }
    }
  }
}
