@import "styles/shared";

.Chapter-result {
  text-align: left;
  &__header {
    @include theme-background-color(c_section-bg);
    @include theme-color(c_body-text);
    padding: $m_default $m_gutter_default;
    @include gutters;
  }
  &__headerdesc {
    @include theme-color(c_more-faded);
    @include theme-font-size(s_default);
    line-height: $lh_xsmall;
    display: block;
    margin-bottom: $m_xsmall;
  }
  &__chaptertitle {
    @include heading(4);
  }
  &__notasks {
    padding: $m_default 0;
    @include gutters;
  }
}
