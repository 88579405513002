@import "styles/shared";

.Task-interaction-order {
  &__list {
    
  }
  &__listitem {
    @include theme-background-color(c_section-inverse-bg);
    padding: $m_default 0 0 $m_gutter_default;
    display: flex;
    position: relative;
    transition: background-color 1s ease;
    
    &--isLast {
      // padding-bottom: $m_default;
      .Task-interaction-order__text {
        border-bottom: 0 none;
      }
    }
    &--animate {
      @include theme-background-color(c_progress-faded);
      transition-duration: 0s;
    }
  }
  &__text {
    @include theme-font-size(s_medium_small);
    line-height: $lh_xsmall;
    @include theme-font-weight(fw_medium);
    padding: 0 0 $m_default 0;
    border-bottom: 1px solid transparent;
    @include theme-border-color(c_more-faded);
    flex: 1;
    margin-right: $m_small;
    @media all and (min-width: $beta_query) {
      @include theme-font-size(s_medium_large);
      padding: 0 0 $m_large 0;
    }
  }
  &__ordernum {
    display: block;
    @include theme-font-size(s_medium_small);
    @include theme-color(c_more-faded);
    margin-bottom: $m_xsmall;
  }

  &__btnwrap {
    margin-left: auto;
    // border-bottom: 1px solid transparent;
    @include theme-border-color(c_more-faded);
  }

  &__downbtn,
  &__upbtn {
    text-align: center;
    @include theme-background-color(c_body-bg);
    height: 100%;
    padding: $m_default $m_gutter_default;
    display: inline-block;
    svg {
      transform: rotate(270deg);
      display: inline-block;
      height: 12px;
      width: 12px;
      margin-bottom: -2px;
      @media all and (min-width: $beta_query) {
        height: 16px;
        width: 16px;
      }
      path {
        @include theme-fill(c_progress);
      }
    }
  }
  &__downbtn {
    @include theme-background-color(c_body-bg-darker);
  }
  &__upbtn {
    svg {
      transform: rotate(90deg);
    }
  }

  // Debug correct
  &__dc {
    position: absolute;
    left: 60%;
    top: 0;
    @include theme-color(c_success);
    @include theme-background-color(c_body-bg);
    display: inline-block;
    padding: 3px;
  }
}
