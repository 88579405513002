@import "styles/shared";

.Facts-section {
  position: relative;
  transition: background $tr_default-speed ease;
  &--largeOnSmallerScreen {
    .Facts-section__heading {
      @media all and (min-width: $charlie_query) {
        @include heading(3);
      }
    }
    .Facts-section__toggler {
      @media all and (min-width: $charlie_query) {
        @include plussign($width: 16px);
        right: $m_gutter_xlarge;
        position: absolute;
      }
    }
  }
  &--isExpanded {
    @include theme-color(c_inverse-text);
    @include theme-background-color(c_inverse-bg);
    .Facts-section__heading {
      @include theme-color(c_inverse-text);
      border-bottom: 2px solid transparent;
      @include theme-border-color(c_inverse-heading-border);
    }
    .Facts-section__toggler {
      transform: translateY(-50%) rotate(45deg);
      &:before,
      &:after {
        @include theme-background-color(c_link-inverse, true);
      }
    }
  }

  &__header {
    @include gutters;
    position: relative;
    cursor: pointer;
  }
  &__heading {
    @include heading(4);
    @include theme-font-weight(fw_prominentheadings);
    padding: $m_medium_large $m_gutter_large $m_medium_large 0;
    @include theme-color(c_body-text);

    // In buc theme, we don't want prominent headings weight
    // here since it's the light weight
    @include theme-diverge($theme-name_buc) {
      font-weight: map-deep-get($THEMES, $theme-name_buc, "fw_medium");
    }
    @media all and (min-width: $delta_query) {
      @include heading(3);
    }
  }
  &--isCollapsed:not(.Facts-section--isLast) {
    .Facts-section__heading {
      border-bottom: 2px solid transparent;
      @include theme-border-color(c_body-bg);
    }
  }

  &__listen {
    margin: $m_default 0;
  }

  &__innerblocks {
    padding: 0 0 $m_default;
    @include gutters;
    @media all and (min-width: $beta_query) {
      padding-bottom: $m_large;
    }
    @include clearfix;
  }

  &__toggler {
    @include plussign($width: 12px);
    right: $m_gutter_default;
    position: absolute;
    @media all and (min-width: $beta_query) {
      @include plussign($width: 16px);
      right: $m_gutter_large;
      position: absolute;
    }
    @media all and (min-width: $delta_query) {
      @include plussign($width: 16px);
      right: $m_gutter_xlarge;
      position: absolute;
    }
    top: 50%;
    transform: translateY(-50%);
  }
}
