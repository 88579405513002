@import "styles/shared";

$double-icon-width: 24px;
.Course-section-chapter-nav-item {
  border-bottom: 2px solid transparent;
  @include theme-border-color(c_delimiter-bg);
  @include theme-background-color(c_body-bg);
  position: relative;
  text-align: left;
  &__link {
    display: block;

    // Extra padding on the right for svg
    padding: $m_default ($double-icon-width + ($m_gutter_default * 2))
      $m_default $m_gutter_default;
    @media all and (min-width: $beta_query) {
      // padding-top: $m_large;
      padding-bottom: $m_large * 0.75;
      padding-left: $m_gutter_large;
      padding-right: ($double-icon-width + ($m_gutter_default * 2));
    }
    @media all and (min-width: $delta_query) {
      padding-left: $m_gutter_xlarge;
    }
  }
  &__chapternumber {
    display: block;
    @include theme-color(c_more-faded);
    @include theme-font-size(s_medium);
  }
  &__chaptertitle {
    @include heading(4);
    @include theme-color(c_body-text);
    display: block;
  }
  &__doubleicon,
  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $m_gutter_default;
    @media all and (min-width: $beta_query) {
      right: $m_gutter_large;
    }
    width: 12px;
    height: 11px;
    svg {
      vertical-align: top;
      display: inline;
    }
  }
  &__doubleicon {
    width: $double-icon-width;
    height: 11px;
  }

  &--status3 {
    @include theme-background-color(c_section-bg);
    .Course-section-chapter-nav-item__chapternumber {
      @include theme-color(c_body-text);
    }
    .Course-section-chapter-nav-item__chaptertitle {
      @include theme-color(c_progress);
    }
    svg path {
      @include theme-stroke(c_progress);
    }
  }
  &--status5 {
    @include theme-background-color(c_section-bg);
    .Course-section-chapter-nav-item__chapternumber {
      @include theme-color(c_body-text);
    }
    .Course-section-chapter-nav-item__chaptertitle {
      @include theme-color(c_success);
    }
    svg path {
      @include theme-stroke(c_success);
    }
  }
  &--active {
    border-bottom: 0 none;
    @include theme-background-color(c_inverse-bg);
    .Course-section-chapter-nav-item__chapternumber,
    .Course-section-chapter-nav-item__chaptertitle {
      @include theme-color(c_inverse-text);
    }
    svg path {
      @include theme-stroke(c_inverse-text);
    }
  }
}
