@mixin heading($level: 3) {
  letter-spacing: -0.2px;
  color: inherit;
  text-decoration: none;

  @if $level == 1 {
    @include theme-font-size(s_xlarge);
    line-height: $lh_small;
    @include theme-font-weight(fw_prominentheadings);
    span {
      @include theme-font-weight(fw_prominentheadings);
    }
  }

  @if $level == 2 {
    @include theme-font-size(s_large);
    line-height: $lh_xsmall;
    // TODO theme size
    @include theme-font-weight(fw_prominentheadings);
    span {
      @include theme-font-weight(fw_prominentheadings);
    }
  }
  @if $level == 3 {
    @include theme-font-size(s_medium_large);
    line-height: $lh_small;
    @include theme-font-weight(fw_bold);
  }
  @if $level == 4 {
    @include theme-font-size(s_medium);
    line-height: $lh_small;
    @include theme-font-weight(fw_bold);
    letter-spacing: -0.1px;
    // @media all and (min-width: $beta_query) {
    //   @include theme-font-size(s_medium_large);
    // }
  }
  @if $level == 5 {
    @include theme-font-size(s_medium_small);
    line-height: $lh_medium;
    @include theme-font-weight(fw_bold);
    letter-spacing: 0;
  }
  @if $level == 6 {
    @include theme-font-size(s_default);
    line-height: $lh_medium;
    @include theme-font-weight(fw_bold);
    @media all and (min-width: $beta_query) {
      @include theme-font-size(s_medium_small);
    }
  }
}

@mixin overlayHeading() {
  @include heading(2);
  padding: $m_xxlarge 0 $m_default;
  margin: 0 0 $m_default;
  color: inherit;
  border-bottom: 3px solid transparent;
  @include theme-border-color(c_inverse-heading-border);
  text-align: center;
  @media all and (min-width: $beta_query) {
    padding-bottom: $m_large;
    margin-bottom: $m_large;
    padding-top: $m_xxxlarge;
  }
  @media all and (min-width: $delta_query) {
    @include heading(1);
  }

  // Buc theme has some more space under
  // the overlay heading
  @include theme-diverge($theme-name_buc) {
    padding-bottom: $m_medium_large;
    margin-bottom: $m_medium_large;
    @media all and (min-width: $beta_query) {
      padding-bottom: $m_large;
      margin-bottom: $m_large;
    }
  }
}
