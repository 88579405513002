@import "styles/shared";

.Task-initial-content {
  &__image {
    margin: $m_default 0;
    img {
      @include image;
    }
  }
  &__rich-text {
    @include richtext;
  }
}
