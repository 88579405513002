@import "styles/shared";

.Course-section-breadcrumb {
  display: flex;
  flex-wrap: wrap;
  &__coursename {
    @include heading(5);

    @include theme-color(c_more-faded);
    flex: 0;
    padding: 0 $m_gutter_default 0 0;
    margin: 0 $m_gutter_default/2 0 0;
    position: relative;
    white-space: nowrap;
    @media all and (min-width: $beta_query) {
      @include heading(2);
      @include theme-color(c_more-faded);
      padding: 0 $m_medium_large 0 0;
      margin: 0 $m_gutter_default 0 0;
    }
    &:after {
      content: "/";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &__sectionname {
    @include heading(5);
    @media all and (min-width: $beta_query) {
      @include heading(2);
    }
  }
  &__backicon {
    display: inline-block;
    margin-right: $m_small;
    vertical-align: middle;
    @media all and (min-width: $beta_query) {
      margin-right: $m_gutter_default;
    }
    svg {
      @media all and (min-width: $beta_query) {
        height: 24px;
        width: 12px;
      }
      path {
        @include theme-fill(c_action-primary);
      }
    }
  }
  
  &__progresswrap {
    margin-top: $m_small;
    flex: 1 0 100%;
    @media all and (min-width: $beta_query) {
      margin-top: $m_default;
      margin-bottom: $m_default;
    }
    &--text {
      display: none;
    }
  }
  &--isInNav {
    &.Course-section-breadcrumb--status1,
    &.Course-section-breadcrumb--status2, 
    &.Course-section-breadcrumb--status5 {
      .Course-section-breadcrumb__coursename,
      .Course-section-breadcrumb__sectionname {
        @include theme-color(c_inverse-text-emph);
      }
    }

    .Course-section-breadcrumb__progresswrap--bar {
      display: none;
    }
    .Course-section-breadcrumb__progresswrap--text {
      display: block;
    }
    .Course-section-breadcrumb__backicon {
      svg path {
        @include theme-fill(c_inverse-text-emph);
      }
    }
  }
}
