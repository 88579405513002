@import "styles/shared";

.Course {
  @include standardpage;
  padding-bottom: $m_default;

  @media all and (min-width: $beta_query) {
    padding-bottom: $m_large;
  }
  &--insection {
    padding-bottom: 0;
    max-width: $w_total-maxwidth;

    .Course__inner {
      @media all and (min-width: $charlie_query) {
        display: flex;
      }
    }
    
   
    // .Course__headerwrap {
    //   flex: 1 0 100%;
    // }
  }
}
