@mixin generalcopymaxwidth() {
  max-width: $w_general-copy-maxwidth;
  @media all and (min-width: $delta_query) {
    max-width: $w_general-copy-maxwidth-large;
  }
  .is-resource-page &, &.is-resource-page {
    @media all and (min-width: $charlie_query) {
      max-width: $w_general-copy-maxwidth-large;
    }
  }
  strong {
    @include theme-font-weight(fw_bold);
  }
}