@import "styles/shared";

.Task-group-progress {
  &__progressbar {
    height: 3px;
    width: 100%;
    border-radius: $ra_default;
    @include theme-background-color(c_body-bg);

    @media all and (min-width: $charlie_query) {
      height: 6px;
      border-radius: $ra_large;
    }
    &-inner {
      background-color: transparent;
      height: 100%;
      border-radius: $ra_default;
      @media all and (min-width: $charlie_query) {
        border-radius: $ra_large;
      }
      transition: width 0.5s ease, background 0.5s ease;
      @include theme-background-color(c_progress-inverse);
      @include theme-box-shadow(
        c_progress-inverse-faded,
        false,
        0px,
        0px,
        12px
      );
    }
  }
  &__progresstext {
    text-align: left;
    margin: $m_default 0;
    @include theme-font-size(s_medium_small);
    @include theme-color(c_body-bg);
    @media all and (min-width: $beta_query) {
      @include theme-font-size(s_medium);
    }
    @include theme-font-weight(fw_medium);
    span {
      @include theme-font-weight(fw_medium);
    }
  }
}
