@import "styles/shared";

.Overlay-close-button {
  display: block;
  position: relative;
  float: right;
  @include theme-background-color(c_section-bg);
  @include theme-color(c_button-inverse-text);
  
  padding: $m_small;
  z-index: 2;
  @media all and (min-width: $beta_query) {
    padding: $m_default $m_gutter_default $m_default $m_gutter_default + 2px;
  }
  &--absolutePos {
    top: 0;
    right: 0;
    float: none;
    position: absolute;
  }
  &--transparent {
    background: none transparent;
  }

  &__icon {
    @include plussign($width: 9px);
    margin-left: $m_xsmall;
    transform: rotate(45deg);
    @media all and (min-width: $beta_query) {
      @include plussign($width: 14px);
      margin-bottom: -1px;
      margin-left: $m_small - 2px;
    }
  }
}
