@import "styles/shared";

@mixin puzzle($borderColor: c_backdrop, $borderStyle: solid) {
  content: "";
  position: absolute;
  width: 8px;
  height: 14px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  border: 1px transparent;
  border-style: $borderStyle;
  border-right: 0;
  top: 50%;
  left: -8px;
  transform: translateY(-50%);
  @include theme-border-color($borderColor, $isPseudo: true);
  @include theme-background-color(c_backdrop, $isPseudo: true);
}

.Task-match-option-card {
  display: flex;
  align-items: center;
  position: relative;
  @include theme-font-size(s_medium_small);
  line-height: $lh_xsmall;
  @include theme-font-weight(fw_medium);
  height: $h_task-match-option-card;
  hyphens: auto;
  -webkit-hyphenate-character: auto;
  -moz-hyphens: auto;
  cursor: move;
  border: 1px solid transparent;
  @include theme-border-color(c_backdrop);
  @include theme-background-color(c_backdrop);
  padding: $m_default;
  margin-left: $m_default;
  margin-bottom: $m_default;
  &:not(&--puzzled):not(&--drop)::before {
    @include puzzle($borderColor: c_backdrop, $borderStyle: solid);
  }
  &--drop:not(&--puzzled)::before {
    @include puzzle($borderColor: c_body-bg-darker, $borderStyle: dashed);
  }
  &--active,
  &--drop {
    opacity: 0.7;
  }
  &--puzzled {
    margin-left: 0;
    margin-right: $m_default;
    border: 1px solid transparent;
    @include theme-border-color(c_inverse-bg);
    @include theme-background-color(c_progress);
  }
  &--drop {
    border: 1px dashed transparent;
    @include theme-border-color(c_body-bg-darker);
    @include theme-background-color(c_backdrop);
  }
  &--hovered {
    opacity: 1;
  }
  &__text {
    // truncate at 3 lines
    display: -webkit-inline-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-height: 48px;
    overflow: hidden;
    white-space: normal;
    padding: 1px 0;
  }
}
