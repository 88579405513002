@charset "UTF-8";
// MAIN RESET
//==============================================================================================================

html,
body,
main,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
option,
a,
input,
button,
select,
textarea,
adress{
  margin:0;
  padding:0;
  border:0;
  border-radius: 0;
  //outline:0;
  font-size:100%;
  vertical-align:baseline;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  background:transparent;
}

adress,
img,
object,
embed{
  display: block;
}

input,
button,
select {
  vertical-align: middle;
}

button,
select{
	cursor: pointer;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

input:focus{
	//outline: 0;
}

button:focus {
  outline: 0;
}

input[type="submit"]::-moz-focus-inner {
	border: 0;
}

ol,
ul{
	list-style: none;
}

a{
  text-decoration: none;
}

// http://christophzillgens.com/en/articles/equal-height-input-and-button-elements-in-firefox-and-safari
//https://github.com/paulirish/html5-boilerplate/blob/362d0b7/css/style.css#L35-37
