@mixin footerlink() {
  @include link($useChevron: true);
  @include theme-font-size(s_medium);
  @include theme-font-weight(fw_medium);
  svg path {
    // Don't use standard chevron color
    // from link mixin in buc theme
    @include theme-diverge($theme-name_buc) {
      fill: map-deep-get($THEMES, $theme-name_buc, "c_action-primary");
    }
  }
}
