// https://www.sitepoint.com/sass-theming-neverending-story/

@mixin themify($property, $key, $themes, $isPseudo: false) {
  // Iterate over the themes
  @each $theme, $vars in $themes {
    // Create a selector (e.g. `.media.theme-unicorn, .theme-unicorn .media`)
    .theme-#{$theme} & {
      // Output the declaration
      #{$property}: map-get($vars, $key);
    }
    @if $isPseudo == false {
      &.theme-#{$theme} {
        #{$property}: map-get($vars, $key);
      }
    }
  }
}

@mixin theme-diverge($theme) {
  .theme-#{$theme} & {
    @content;
  }
}


// Use these for theming from common properties in $THEMES

@mixin theme-color($color, $isPseudo: false) {
  @include themify("color", $color, $THEMES, $isPseudo);
}

@mixin theme-background-color($color, $isPseudo: false) {
  @include themify("background-color", $color, $THEMES, $isPseudo);
}

@mixin theme-border-color($color, $isPseudo: false) {
  @include themify("border-color", $color, $THEMES, $isPseudo);
}

@mixin theme-border-top-color($color, $isPseudo: false) {
  @include themify("border-top-color", $color, $THEMES, $isPseudo);
}

@mixin theme-border-right-color($color, $isPseudo: false) {
  @include themify("border-right-color", $color, $THEMES, $isPseudo);
}

@mixin theme-border-bottom-color($color, $isPseudo: false) {
  @include themify("border-bottom-color", $color, $THEMES, $isPseudo);
}

@mixin theme-border-left-color($color, $isPseudo: false) {
  @include themify("border-left-color", $color, $THEMES, $isPseudo);
}

@mixin theme-stroke($color, $isPseudo: false) {
  @include themify("stroke", $color, $THEMES, $isPseudo);
}

@mixin theme-fill($color, $isPseudo: false) {
  @include themify("fill", $color, $THEMES, $isPseudo);
}

@mixin theme-border-radius($ra, $isPseudo: false) {
  @include themify("border-radius", $ra, $THEMES, $isPseudo);
}


@mixin theme-box-shadow(
  $color,
  $isPseudo: false,
  $hoffset: 0px,
  $voffset: 0px,
  $blur: 0px
) {
  @each $theme, $colors in $THEMES {
    // Create a selector (e.g. `.theme-unicorn .media`)
    .theme-#{$theme} & {
      // Output the declaration
      box-shadow: $hoffset $voffset $blur map-get($colors, $color);
    }
  }
}

@mixin theme-font-family($ff, $isPseudo: false) {
  @include themify("font-family", $ff, $THEMES, $isPseudo);
}

@mixin theme-font-weight($fw, $isPseudo: false) {
  @include themify("font-weight", $fw, $THEMES, $isPseudo);
}

@mixin theme-font-size($s, $isPseudo: false) {
  @include themify("font-size", $s, $THEMES, $isPseudo);
}

