@mixin plussign($width: 12px) {
  position: relative;
  display: inline-block;
  width: $width;
  height: $width;
  transition: transform $tr_default-speed ease,
    background $tr_default-speed ease;
  &:before,
  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 2px;
    @include theme-background-color(c_action-primary, true);
    top: 50%;
    margin-top: -1px;
    left: 0;
  }
  &:after {
    transform: rotate(90deg);
  }
}
