@import "styles/shared";

$image-map-tooltip-y-comp: 21px;

.Image-map-tooltip {
  position: absolute;
  @include theme-background-color(c_inverse-bg);
  padding: $m_default;
  border: 1px solid transparent;
  @include theme-border-color(c_shadow-weak);
  @include theme-color(c_inverse-text);

  min-width: 150px;
  z-index: 1;
  @include theme-border-radius(ra_default);
  @include theme-font-size(s_default);

  transform: translate3d(-50%, $image-map-tooltip-y-comp, 0px);

  width: calc(100% - 24px);

  left: 50%;

  &__arrow {
    display: block;
    position: absolute;
    left: 10px;
    top: -9px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;

    border-bottom: 9px solid transparent;
    @include theme-border-bottom-color(c_inverse-bg);
    transform: translate3d(-50%, 0px, 0px);
  }
  &--fromRight {
    transform: translate3d(-50%, $image-map-tooltip-y-comp, 0px);
  }

  &--isBig {
    transform: translate3d(-19px, $image-map-tooltip-y-comp, 0px);
    left: auto;
    width: auto;
    max-width: 300px;
    .Image-map-tooltip__arrow {
      transform: translate3d(0px, 0px, 0px);
    }
  }
  &.Image-map-tooltip--fromRight.Image-map-tooltip--isBig {
    transform: translate3d(19px, $image-map-tooltip-y-comp, 0px);
    .Image-map-tooltip__arrow {
      left: auto;
      right: 10px;
    }
  }
  &__heading {
    @include heading(4);
    margin-bottom: $m_xsmall;
  }
  &__text {
    display: block;
    margin-bottom: $m_xsmall;
  }
  &__close {
    @include link($inverse: true);
  }
}
