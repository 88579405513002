@import "styles/shared";

.Carousel-item {
  min-width: 200px;
  max-width: 1065px;
  &__image {
    img {
      @include image;
    }
  }
  &__content {
    padding-right: $m_gutter_default;
    @include keeptextmedium;
  }
}
