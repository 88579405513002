@import "styles/shared";

.Task-content {
  @include defaulttypo;
  @include theme-color(c_inverse-text);
  &__heading {
    @include heading(5);
    margin-bottom: $m_default;
  }
  &__image {
    margin: $m_default 0;
    img {
      @include image;
    }
  }
  &__rich-text {
    @include richtext;
  }
}
