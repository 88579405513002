@import "styles/shared";

.Resource-page {
  @include standardpage;
  max-width: $w_resource-page-maxwidth;
  

  @media all and (min-width: $beta_query) {
    padding-bottom: 0;
  }
  &__hero {
    display: flex;
  }
  &__herotext {
    padding-top: $m_xlarge;
    padding-bottom: $m_xlarge;
    position: relative;
    @include gutters;
    flex-grow: 1;
  }
  &__heroheading {
    @include heading(2);

    @media all and (min-width: $beta_query) {
      @include heading(1);
    }
    @include theme-font-weight(fw_black);
  }
  &__herosubheading {
    margin-top: $m_medium;
    @include heading(4);
    @media all and (min-width: $beta_query) {
      @include heading(3);
      @include theme-font-weight(fw_medium);
    }
  }
  &__herologo {
    position: relative;
    flex-grow: 0;
    @include gutters;
    align-self: center;
    img {
      width: auto;
      height: 100px;
      @media all and (min-width: $beta_query) {
        height: 120px;
      }
      display: inline-block;
    }
  }
  &--withHeroImg {
    padding-top: 0;
    .Resource-page__hero {
      position: relative;
      &bg {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        min-height: 100px;
        background-size: cover;
        background-position: center center;
      }
      &text {
        padding-top: $m_xxxlarge;
        padding-bottom: $m_xxxlarge;
      }
      &heading,
      &subheading {
        text-shadow: 0px 3px 6px rgba(#000, 0.82);
        @media all and (min-width: $charlie_query) {
          padding: 0 0 0 $m_xxlarge;
        }
      }
    }
  }
  &__content {
    @include gutters;
    padding-top: $m_large;
  }

  &__section {
    margin: 0 -#{$m_gutter_default};
    @media all and (min-width: $beta_query) {
      margin: 0 -#{$m_gutter_large};
    }
    @media all and (min-width: $charlie_query) {
      margin: 0 -#{$m_gutter_xlarge};
    }
  }
  &__footer {
    padding: $m_xxlarge 0;
    @include gutters;
  }
  &__footerinner {
    padding: $m_xxlarge 0 0;
    // @include gutters;
    border-top: 2px solid transparent;
    @include theme-border-color(c_section-bg);
  }
  &__footerlink {
    @include footerlink;
  }
}
