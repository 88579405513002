@import "styles/shared";

.Task-option {
  display: block;
  position: relative;
  border: 1px solid transparent;
  @include theme-border-color(c_border-inverse);
  @include theme-border-radius(ra_default);
  @include theme-background-color(c_section-inverse-bg);
  padding: $m_default $m_gutter_default * 2.5 $m_default $m_gutter_default;
  @include theme-font-size(s_medium);
  line-height: $lh_small;

  @media all and (min-width: $delta_query) {
    padding: $m_medium_large $m_gutter_large * 2.5 $m_medium_large $m_gutter_large;
  }
  cursor: pointer;
  &__element {
    position: absolute;
    left: -999em;
  }
  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $m_default;
    width: 12px;
    height: 11px;
    @media all and (min-width: $delta_query) {
      width: 18px;
      height: 16px;
      right: $m_gutter_large;
    }
    svg {
      width: 100%;
      height: 100%;
      vertical-align: top;
      path {
        @include theme-stroke(c_inverse-text);
      }
    }
  }
  &__image {
    height: 60px;
    margin: 0 auto $m_small;
    text-align: center;
    @media all and (min-width: $delta_query) {
      height: 96px;
      margin: 0 auto $m_medium_large;
    }
    img {
      @include image;
      height: 100%;
    }
  }
  &__text {
    word-wrap: break-word;
  }
  &--isselected {
    @include theme-background-color(c_progress-faded);
    .Task-option__text {
      @include theme-font-weight(fw_medium);
    }
  }
  &--hasimg {
    padding: $m_large $m_default;
    text-align: center;
    @include theme-font-size(s_medium_small);
    line-height: $lh_xsmall;
    @media all and (min-width: $delta_query) {
      @include theme-font-size(s_medium_large);
    }

    flex: 1 0 auto; // Same height
    .Task-option__icon {
      display: none;
    }
  }

  // Only in debug mode, show if correct
  &--dc {
    &:after {
      content: " ";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 10px;
      bottom: 0;
      @include theme-background-color(c_success, true);
    }
  }
}
