@mixin link($underline: false, $useChevron: false, $inverse: false) {
  appearance: none;
  display: inline-block;
  text-decoration: none;
  @include theme-font-family(ff_default);
  @include theme-color(c_link);

  @if $underline {
    text-decoration: underline;
  }
  @if $inverse {
    @include theme-color(c_link-inverse);
  }
  @if $useChevron {
    svg {
      transform: rotate(180deg); // We assume this is back.svg
      height: 12px;
      width: 6px;
      display: inline-block;
      margin-right: $m_small;
      vertical-align: middle;
      margin-top: -5px;
      path {
        @include theme-fill(c_more-faded);
      }
      @media all and (min-width: $beta_query) {
        height: 16px;
        width: 8px;
      }
    }
  }
  
}

// TODO chevron
