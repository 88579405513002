@import "styles/shared";


%preface-paragraph {
  strong {
    @include theme-font-weight(fw_bold);
  }
  @media all and (min-width: $beta_query) {
    margin-top: $m_default;
  }
}
%preface-subheading {
  @include heading(5);
  margin-top: $m_medium_large;
  @include theme-color(c_success);
  @media all and (min-width: $beta_query) {
    @include heading(3);
    margin-top: $m_large;
    @include theme-color(c_success);
  }
}
.Preface {
  &__inner {
    padding: 0 0 $m_large 0;
    @include gutters;
    @include theme-font-size(s_medium_small);
    line-height: $lh_medium;
    @media all and (min-width: $beta_query) {
      @include theme-font-size(s_medium_large);
    }
  }
  &__video {
    margin-bottom: $m_large;
  }
  &__image {
    margin-bottom: $m_large;
    img {
      @include image;
      width: 100%;
    }
  }
  &__heading {
    @include heading(3);
    @media all and (min-width: $beta_query) {
      @include heading(1);
    }
    margin: 0 0 $m_default 0;
    @media all and (min-width: $beta_query) {
      margin: 0 0 $m_large 0;
    }
  }
  &__subheading {
    @extend %preface-subheading;
  }
  &__list {
    @include numberedlist;
    @media all and (max-width: $beta_query - 1px) {
      margin-top: 0;
    }
  }
  &__paragraph {
    @extend %preface-paragraph;
  }
  &__text {
    p {
      @extend %preface-paragraph;
    }
    h2 {
      @extend %preface-subheading;
    }
    ul {
      @include bulletlist;
    }
    ol {
      @include numberedlist;
    }
    a {
      @include link($inverse: false);
    }
  }
  &__moveon {
    @include button($type: primary, $blocked: true);
    margin-top: $m_large;
    @media all and (min-width: $beta_query) {
      margin-top: $m_xlarge;
    }
  }
}
