@import "styles/shared";

.Task {
  // @include generalcopymaxwidth;
  
  // margin: 0 auto;
  // @media all and (min-width: $delta_query) {
  //   position: relative;
  // }
  &__header {
    padding: 0 0;
  }
  &__content {
    padding: 0 0 $m_default;
  }
  &__answercontainer {
  }
  &__submit {
    padding: $m_default 0;
    text-align: center;
  }
  &__submitbtn {
    @include button($blocked: true, $type: "inverse");
    @media all and (min-width: $delta_query) {
      @include button($blocked: true, $type: "inverse", $extraHeight: true);
    }
  }
  &__cancellink {
    @include link($inverse: true);
    margin: $m_large 0;
    line-height: $lh_large;
  }
  &__cancellink-highlight {
    @include theme-font-weight(fw_bold);
  }

  // Debug div
  &__d {
    ul {
      @include bulletlist;
    }
  }
}
