@import "styles/shared";

.Participant-course-summary {
  @include standardpage;

  &__nav {
    padding-top: $m_default;
    padding-bottom: $m_default;
    @include gutters;

  }
  &__backlink {
    @include link();
    svg {
      margin-right: $m_gutter_default;
    }
    @include theme-font-weight(fw_bold);
    @include theme-color(c_more-faded);
  }
}
