@import "styles/shared";

.Task-interaction-text {
  &__item {
    margin-bottom: $m_small;
    &--last {
      margin-bottom: 0;
    }
  }
}
