@import "styles/shared";

.Task-interaction-image {
  // @include gutters;
  &__list {
    display: flex;
    flex-wrap: wrap;
    // @include gutters;
    margin: 0 -#{$m_gutter_default/2} -#{$m_gutter_default};
  }
  &__item {
    flex: 0 0 50%;
    width: 50%;
    padding: 0 $m_gutter_default/2;
    margin-bottom: $m_gutter_default;

    // Same height
    display: flex;
    flex-direction: column;
  }
}