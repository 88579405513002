@import "styles/shared";

.Vimeo-video {
  position: relative;
  padding: 0 0 56.25%; // 16:9
  iframe {
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
