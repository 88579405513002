@import "styles/shared";

.Participant-search-result {
  @include theme-background-color(c_inverse-bg);
  @include theme-color(c_inverse-text);
  padding: $m_large;
  @include gutters;

  &__name {
    @include heading(3);
  }

  &__details {
    margin: $m_large 0;
  }
  &__courselink {
    border-top: 2px solid transparent;
    @include theme-border-top-color(c_border-inverse);
    padding: $m_default 0;
    display: block;
    width: 100%;
    text-align: left;
    position: relative;
    @include theme-color(c_inverse-text);
    svg {
      transform: rotate(180deg) translateY(50%); // We assume this is back.svg
      height: 12px;
      width: 6px;
      display: inline-block;
      position: absolute;
      right: 0;
      top: 50%;

      path {
        @include theme-fill(c_link-inverse);
      }
    }
  }
}
