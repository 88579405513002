@import "styles/shared";

.Fact-content-modal-overlay {
  &__closelink {
    @include link($inverse: true);
    display: inline-block;
    margin-top: $m_default;
  }
  &__blocks {
    // Ugly exception from css conventions,
    // sorry not sorry
    [class^="Fact-block"]:first-child {
      margin-top: 0;
    }
  }
}
